// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activer-carte-js": () => import("./../src/pages/activer_carte.js" /* webpackChunkName: "component---src-pages-activer-carte-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bons-dachat-erreur-js": () => import("./../src/pages/bons_dachat_erreur.js" /* webpackChunkName: "component---src-pages-bons-dachat-erreur-js" */),
  "component---src-pages-bons-dachat-succes-js": () => import("./../src/pages/bons_dachat_succes.js" /* webpackChunkName: "component---src-pages-bons-dachat-succes-js" */),
  "component---src-pages-callback-js": () => import("./../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-cancel-booking-js": () => import("./../src/pages/cancel_booking.js" /* webpackChunkName: "component---src-pages-cancel-booking-js" */),
  "component---src-pages-cancel-workshop-js": () => import("./../src/pages/cancel_workshop.js" /* webpackChunkName: "component---src-pages-cancel-workshop-js" */),
  "component---src-pages-email-verification-js": () => import("./../src/pages/email_verification.js" /* webpackChunkName: "component---src-pages-email-verification-js" */),
  "component---src-pages-offrir-carte-js": () => import("./../src/pages/offrir_carte.js" /* webpackChunkName: "component---src-pages-offrir-carte-js" */),
  "component---src-pages-reservation-erreur-js": () => import("./../src/pages/reservation_erreur.js" /* webpackChunkName: "component---src-pages-reservation-erreur-js" */),
  "component---src-pages-reservation-succes-js": () => import("./../src/pages/reservation_succes.js" /* webpackChunkName: "component---src-pages-reservation-succes-js" */),
  "component---src-templates-atelier-js": () => import("./../src/templates/atelier.js" /* webpackChunkName: "component---src-templates-atelier-js" */),
  "component---src-templates-birthdays-js": () => import("./../src/templates/birthdays.js" /* webpackChunkName: "component---src-templates-birthdays-js" */),
  "component---src-templates-bookings-js": () => import("./../src/templates/bookings.js" /* webpackChunkName: "component---src-templates-bookings-js" */),
  "component---src-templates-cards-js": () => import("./../src/templates/cards.js" /* webpackChunkName: "component---src-templates-cards-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faq-js": () => import("./../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-fonctionnement-js": () => import("./../src/templates/fonctionnement.js" /* webpackChunkName: "component---src-templates-fonctionnement-js" */),
  "component---src-templates-gallery-js": () => import("./../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-origin-js": () => import("./../src/templates/origin.js" /* webpackChunkName: "component---src-templates-origin-js" */),
  "component---src-templates-simple-js": () => import("./../src/templates/simple.js" /* webpackChunkName: "component---src-templates-simple-js" */),
  "component---src-templates-spaces-js": () => import("./../src/templates/spaces.js" /* webpackChunkName: "component---src-templates-spaces-js" */),
  "component---src-templates-teaching-js": () => import("./../src/templates/teaching.js" /* webpackChunkName: "component---src-templates-teaching-js" */),
  "component---src-templates-workshops-js": () => import("./../src/templates/workshops.js" /* webpackChunkName: "component---src-templates-workshops-js" */)
}

