import auth0 from 'auth0-js';
import { navigate } from "gatsby"
import queryString from 'query-string';

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
  ? new auth0.WebAuth({
    domain: process.env.GATSBY_AUTH0_DOMAIN,
    clientID: process.env.GATSBY_AUTH0_CLIENTID,
    redirectUri: window.location.protocol + '//' + window.location.host + '/callback',
    responseType: "token id_token",
    scope: "openid profile email",
  })
  : {}


const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false
}

let user = false;

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  return localStorage.getItem("isLoggedIn") === "true" && tokens.idToken
}

export const login = () => {
  if (!isBrowser) {
    return
  }

  auth.authorize()
}

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate("/")
    return
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt
    user = authResult.idTokenPayload
    localStorage.setItem("isLoggedIn", true)
    cb()
  }
}


export const checkValidEmail = (search) => {
  const params = queryString.parse(search)
  return params.success
}

export const silentAuth = callback => {
  if (!isBrowser) {
    return;
  }

  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
};

export const logout = () => {
  localStorage.setItem('isLoggedIn', false);
  user = false;

  auth.logout()

  /* const { protocol, host } = window.location;
  const returnTo = `${protocol}//${host}`;

  lock.logout({ returnTo: returnTo }); */
};

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  auth.parseHash(setSession(() => navigate("/app/admin")))
}

export const getAccessToken = () => {
  if (!isBrowser) {
    return '';
  }

  return tokens.accessToken;
};

export const getIdToken = () => {
  if (!isBrowser) {
    return '';
  }

  return tokens.idToken;
};

export const getUser = () => {
  return user;
}

export const isAdmin = () => {
   return user && user['https://lesenfantsdelarbrevert.fr/roles'] && user['https://lesenfantsdelarbrevert.fr/roles'].includes('admin')
}