/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/style.scss"
import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { silentAuth } from './src/utils/auth'
import { Provider } from "react-redux"
import store from "./src/state/store"

class SessionCheck extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }
  
    handleCheckSession = () => {
      this.setState({ loading: false });
    };
  
    componentDidMount() {
      silentAuth(this.handleCheckSession);
    }
  
    render() {
      return (
        this.state.loading === false && (
          <React.Fragment>{this.props.children}</React.Fragment>
        )
      );
    }
  }

const components = {
    blockquote: props => (
        <blockquote className="ledlav-blockquote" {...props} />
    )
}

export const wrapRootElement = ({element}) => {
    return (
        <SessionCheck>
          <Provider store={store}>
            <MDXProvider components={components} >
                {element}
            </MDXProvider>
          </Provider> 
        </SessionCheck>
    )
}
