export const ADD_ARTICLE = "ADD_ARTICLE";
export const DATA_LOADED = "DATA_LOADED";

export function addArticle(payload) {
    return { type: ADD_ARTICLE, payload }
};

export function getData() {
    return function(dispatch) {
      return fetch("https://jsonplaceholder.typicode.com/posts")
        .then(response => response.json())
        .then(json => {
          dispatch({ type: DATA_LOADED, payload: json.slice(0, 10) });
        });
    };
}
